@tailwind base;
@tailwind components;
@tailwind utilities;

::-webkit-scrollbar { 
    width: 10px; 
} 
  
/* Track */
::-webkit-scrollbar-track { 
    background: #f9fafb; 
} 
  
/* Handle */
::-webkit-scrollbar-thumb { 
    background: #cececf; 
    border-radius: 5px; 
} 
  
/* Handle on hover */
::-webkit-scrollbar-thumb:hover { 
    background: #888; 
}