html,
body {
  padding: 0;
  margin: 0;

  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container {
  display: block;
  width: 100%;
}

.customScroll::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

.customScroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px white;
  border-radius: 3px;
}

/* Handle */
.customScroll::-webkit-scrollbar-thumb {
  background: lightgray;
  border-radius: 10px;
}

/* Handle on hover */
.customScroll::-webkit-scrollbar-thumb:hover {
  background: lightblue;
}

.draggableList:hover .dragIcon {
  display: none;
}

.draggableList:hover .plusIcon {
  display: block;
}

.overviewChart canvas {
  height: 260px !important;
}

.Toastify__toast {
  min-height: 55px !important;
}

.Toastify__close-button {
  display: none !important;
}

.height-threshold {
  min-height: 400px !important;
}

.min-h-300 {
  min-height: 300px !important;
}

[aria-disabled="true"] {
  opacity: 0.5;
  cursor: not-allowed !important;
  text-decoration: none;
  user-select: none;
}
